import React, { useState, useEffect } from 'react';
import { searchPlaylists } from '../../services/spotifyService';
import '../../styles/global.css';
import { ReactComponent as Cuadros } from '../../assets/img/cuadros.svg'; // Adjust the path as necessary

function SpotifyPlayer({ artist, playList }) {
  const [playlistUrl, setPlaylistUrl] = useState('');

  useEffect(() => {
    const fetchPlaylists = async () => {
      const playlists = await searchPlaylists(artist, playList);
      if (playlists.length > 0) {
        setPlaylistUrl(playlists[0].external_urls.spotify);
      }
    };

    fetchPlaylists();
  }, [artist]);
  return (
    <div className='center' style={{margin: '0px 0 40px'}}>
      <h4>Spotify playlist</h4>
      <p>I have created a Spotify Playlist for you. You can also share it with your friends.</p>
      {playlistUrl ? (
        <iframe
          src={`https://open.spotify.com/embed/playlist/${playlistUrl.split('/').pop()}`}
          width="300"
          height="380"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      ) : (
        <p>Loading...</p>
      )}
      
    <Cuadros  style={{margin: '40px 0 10px'}}/>
    </div>
  );
}

export default SpotifyPlayer;
