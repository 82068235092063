import React, { useState, useEffect } from 'react';
import { ReactComponent as Grid } from '../../../assets/img/grid.svg'; // Import SVG as React Component
import './AlbumCover.css';

function AlbumCover({albumCover}) {
  return (
    <div className="album-cover">
      <div className="wrapper center">
      {albumCover ? <img src={albumCover} alt="Album Cover" /> : <p>Loading...</p>}
      </div>
    </div>
  );
}

export default AlbumCover;
