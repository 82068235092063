import React, { useState, useRef, useEffect } from 'react';
import './Jam.css';
import { ReactComponent as Share } from '../../../assets/img/arrow-share.svg'; // Import SVG as React Component
import { ReactComponent as Download } from '../../../assets/img/arrow-download.svg'; // Adjust the path as necessary
import cover from '../../../assets/img/download.jpg'; // Import PNG as a standard image file
//import song from '../../../assets/audio/Je-Veux.mp3'; // Import PNG as a standard image file
import AlbumCover from '../album/AlbumCover';
import Song from '../song/Song';

const whatsappLogo = "https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg";
const emailLogo = "https://icon-library.com/images/email-icon-images/email-icon-images-19.jpg";
const linkedinLogo = "https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png";
const twitterLogo = "https://upload.wikimedia.org/wikipedia/commons/c/ce/X_logo_2023.svg";
const githubLogo = "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg";
const instagramLogo = "https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png";


function Jam({ albumCover, song }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const progressRef = useRef(null);
  const timeRef = useRef(null);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  useEffect(() => {
    const audio = document.getElementById('audio');
    const updateProgress = () => {
      if (audio) {
        const currentTime = audio.currentTime;
        const duration = audio.duration;
        const progress = (currentTime / duration) * 100;
        if (progressRef.current) {
          progressRef.current.style.width = progress + '%';
        }

        const formatTime = (time) => {
          const minutes = Math.floor(time / 60);
          const seconds = Math.floor(time % 60);
          return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        };

        const currentTimeFormatted = formatTime(currentTime);
        const durationFormatted = formatTime(duration);
        if (timeRef.current) {
          timeRef.current.textContent = `${currentTimeFormatted} / ${durationFormatted}`;
        }
      }
    };

    if (audio) {
      audio.addEventListener('timeupdate', updateProgress);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('timeupdate', updateProgress);
      }
    };
  }, []);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };
  const handleDownload = (audioSrc) => {

    const link = document.createElement('a');
    link.href = song;
    link.setAttribute('download', 'song.mp3');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShare = (platform) => {
    const url = window.location.href;
    const user_url = url.replace('hash', 'user');
    const text = `Check out my Page`;

    let shareUrl = '';
    switch (platform) {
      case 'email':
        shareUrl = `mailto:?subject=${encodeURIComponent(text)}&body=${encodeURIComponent(user_url)}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodeURIComponent(text + ' ' + user_url)}`;
        break;
      case 'instagram':
        shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(user_url)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(user_url)}`;
        break;
      case 'github':
        shareUrl = `https://github.com/new?title=${encodeURIComponent(text)}&body=${encodeURIComponent(user_url)}`;
        break;
      case 'instagram':
        // Note: This won't post directly to Instagram but will open Instagram with the URL in the caption.
        shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(user_url)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(user_url)}&title=${encodeURIComponent(text)}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, '_blank');
  };
  const toggleShareMenu = () => {
    setIsShareMenuOpen(!isShareMenuOpen);
  };
  return (
    <div className="your-jam">
      <div className="wrapper center">
        <h4>Your Jam:</h4>
        <div className="jam">
          <div className='album-cover-jam'>
            <AlbumCover albumCover={albumCover} />
            <Song audioUrl={song} />
          </div>

        </div>

        <div className="actions">
          <button className="btn download" onClick={() => handleDownload({ song })}>Download <Download /></button>
          <div className="share-menu">
            <button className="btn share" onClick={toggleShareMenu}>Share <Share /></button>
            {isShareMenuOpen && (
              <div className="share-options">
                <button onClick={() => handleShare('email')}><img src={emailLogo} alt="Email" /></button>
                <button onClick={() => handleShare('whatsapp')}><img src={whatsappLogo} alt="WhatsApp" /></button>
                <button onClick={() => handleShare('twitter')}><img src={twitterLogo} alt="Twitter" /></button>
                <button onClick={() => handleShare('linkedin')}><img src={linkedinLogo} alt="LinkedIn" /></button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jam;
