import settings from "../settings";

const clientId = '44bf6a25d9f843fc851b2f718777182d';
const clientSecret = 'b03689785cc14d27a0e2f22088cd110c';


let accessToken = '';

export const getAccessToken = async () => {
  if (accessToken) {
    return accessToken;
  }

  const result = await fetch('https://accounts.spotify.com/api/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(clientId + ':' + clientSecret)
    },
    body: 'grant_type=client_credentials',
    mode: 'cors' // Ensure CORS is enabled on the server if needed
  });

  const data = await result.json();
  accessToken = data.access_token;
  return accessToken;
};

export const searchPlaylists = async (query) => {
  const token = await getAccessToken();
  const result = await fetch(`https://api.spotify.com/v1/search?type=playlist&q=${query}`, {
    method: 'GET',
    headers: { 'Authorization': 'Bearer ' + token }
  });

  const data = await result.json();
  return data.playlists.items;
};
/*export const searchPlaylists = async (artists, playlist_name) => {
  const result = await fetch(`${settings.backendHost}/api/generate/playlist`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ artists: artists, playlist_name: playlist_name })
  });

  if (!result.ok) {
    throw new Error('Failed to generate playlist');
  }

  const data = await result.json();
  return data.playlist_url;
}; */
