import React, { useEffect, useRef, useState } from 'react';
import './Song.css';

function Song({ audioUrl }) {
  return (
    <div>
      {audioUrl && (
        <audio controls>
          <source src={audioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>

      )}
    </div>
  );
}

export default Song;
