import React, { useState } from 'react';
import './UserForm.css';
import settings from '../settings';
import ApiService from '../services/apiService';
const PasswordForm = ({ setAuth }) => {
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await ApiService.authenticate(password);
            setAuth(true);
        } catch (error) {
            console.error('Error:', error);
            alert('Incorrect password');
        }
    };

    return (
        <div className='landing-page'>
            <form onSubmit={handleSubmit} className='landing-form'>
                <div>
                    <label htmlFor='password'>Password:</label>
                    <input
                        type="password"
                        value={password}
                        name='password'
                        id='password'
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button className='submit-button' type="submit">Enter</button>
            </form>
        </div>
    );
};

export default PasswordForm;