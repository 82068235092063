import React, { useState, useEffect } from 'react';
import './QRCode.css';
import settings from '../../../settings';
import apiService from '../../../services/apiService';

function QRCode({ userHash }) {
  const [qrCodeUrl, setQRCodeUrl] = useState('');

  useEffect(() => {
    const fetchQRCode = async () => {
      if (userHash) {
        try {
          const blob = await apiService.fetchQRCode(userHash);
          const url = URL.createObjectURL(blob);
          setQRCodeUrl(url);
        } catch (error) {
          console.error('There was a problem with the QRCode fetch operation:', error);
          setQRCodeUrl('');
        }
      }
    };

    fetchQRCode();
  }, [userHash]);


  return (
    <div className='wrapper center' >
      <div className='qr-code'>
        {qrCodeUrl ? <img src={qrCodeUrl} alt='QR Code' /> : <p>Loading...</p>}
      </div>
    </div>
  );
}

export default QRCode;
