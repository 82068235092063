import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/img/logo.svg'; // Adjust the path as necessary
import { ReactComponent as Lci } from '../../assets/img/logo-lci-lite.svg'; // Adjust the path as necessary
import '../../styles/global.css';
import { Link } from 'react-router-dom';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="logo">
        <Logo /><Lci />
      </div>
      <div className={`menu ${isMenuOpen ? 'open' : ''}`} id="menu" onClick={toggleMenu}>
        <span></span>
        <span></span>
      </div>
      <nav id="nav" className={isMenuOpen ? 'open' : ''}>
        <div>
           <ul>
            <li><Link to="/" onClick={closeMenu}>My Groove</Link></li>
            <li><Link to="/credits" onClick={closeMenu}>Credits</Link></li>
            <li><a href="https://barcelona.lcieducation.com/">LCI Barcelona</a></li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
