// src/api/apiService.js
import settings from '../settings';

class ApiService {

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  async fetchWithAuth(url, options = {}) {
    const authCookie = this.getCookie('Authorization');
  
    if (!authCookie) {
      throw new Error('No authorization cookie found');
    }

    const headers = {
      'Authorization': authCookie,
      ...options.headers,
    };

    const response = await fetch(url, {
      ...options,
      headers,
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return response;
  }

  async authenticate(password) {
    const response = await fetch(`${settings.backendHost}/api/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password }),
      credentials: 'include', // Include credentials to set cookies
    });

    if (!response.ok) {
      throw new Error('Incorrect password');
    }

    return response;
  }

  async getUserData(hash) {
    const response = await fetch(`${settings.backendHost}/api/user-data/${hash}`, {
      method: 'GET',
      mode: 'cors' // Ensure CORS is enabled on the server if needed
    });
    return await response.json();
  }
  async getRecommendations(image, userHash) {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('user_hash', userHash);
    const response = await this.fetchWithAuth(`${settings.backendHost}/api/generate/recommendations`, {
      method: 'POST',
      body: formData,
      mode: 'cors' // Ensure CORS is enabled on the server if needed
    });
  
    return await response.json();
  }

  async generateAlbumCover(imagePrompt, userHash) {
    const response = await this.fetchWithAuth(`${settings.backendHost}/api/generate/art-cover`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors', // Ensure CORS is enabled on the server if needed
      body: JSON.stringify({ prompt: imagePrompt, user_hash: userHash }),
    });
    const data = await response.json();
    return data.image_url;

  }

  async generateSong(prompt, userHash) {
    // Fetch audio stream from the API
    const response = await this.fetchWithAuth(`${settings.backendHost}/api/generate/song`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt: prompt, user_hash: userHash}), // Add any necessary request body here
    });

    if (!response.ok) {
      throw new Error('Failed to generate song');
    }

    const reader = response.body.getReader();
    const chunks = [];
    let receivedLength = 0;

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      chunks.push(value);
      receivedLength += value.length;
    }

    const chunksAll = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }

    const blob = new Blob([chunksAll], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(blob);

    return audioUrl;
  }

  async submitFormData(formData) {
    const response = await this.fetchWithAuth(`${settings.backendHost}/api/get-user-hash`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: formData.name, phone_number: formData.phone, email: formData.email }),
      mode: 'cors',
    });

    if (!response.ok) {
      throw new Error('Error submitting form');
    }

    return await response.json();
  }

  async fetchQRCode(userHash) {
    const response = await this.fetchWithAuth(`${settings.backendHost}/api/generate/qr-code/${userHash}`, {
      method: 'GET',
      mode: 'cors',
    });

    if (!response.ok) {
      throw new Error('Error fetching QR code');
    }

    return response.blob();
  }

}


export default new ApiService();
