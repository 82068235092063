import React from 'react';
import { ReactComponent as Lines } from '../../../assets/img/lines.svg'; 
import './Recommendations.css';
import OutfitTitle from './OutfitTitle';
function Recommendations({ recommendations }) {
  return (
    <div className="recommendations wrapper center">
      {recommendations ? (
        <>
          <div className="outfit-title">
            <p>I have scanned your outfit and here’s some insights I think you will agree with. By the way, you look good.</p>
            <OutfitTitle outfit_title={recommendations.outfit_title} />
          </div>

          <div className="outfit-description">
            <p className='outfit-description'>{recommendations.summary}</p>
          </div>
         
          <div className="music-recommendations">
            <p>According to what you are wearing, I think you should love this:</p>
            <ul className="band-list">
              {recommendations.music_recommendation.map((music, index) => (
                <li key={index}>{music}</li>
              ))}
            </ul>
          </div>

          <div className="recomended-bands">
            <p>Here you have a list of artists I think you will enjoy listening to:</p>
            <ul className="band-list">
              {recommendations.band_recommendation.map((band, index) => (
                <li key={index}>{band}</li>
              ))}
            </ul>
          </div>
          <Lines />
          <div className="playlist-recommendations">
            <h4>Playlists</h4>
            <ul className="band-list">
              {recommendations.playlist_recommendation.map((playlist, index) => (
                <li key={index}>{playlist}</li>
              ))}
            </ul>
          </div>

          
        </>
      ) : (
        <p>No recommendations available.</p>
      )}
    </div>
  );
}

export default Recommendations;
