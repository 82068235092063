// Credits.js
import React from 'react';
import './Credits.css'; // Adjust the path as necessary
import Header from './Header';


function Credits() {
  return (
    <main className="credits">


      <div className="wrapper">
        <h5>The proud team<br />that created GROOVIFY</h5>

        <div className="bg-elipses">
        <h5>AI & App</h5>
          <ul class="peoble">
              <li>Lucas Pastur-Romay</li>
              <li>Dan Fouz</li>
              <li>Pol Roca</li>
              <li>Martin Tripiana</li>
              <li>Oscar Orti</li>
              <li>Mateo Marin</li>
              <li>Jordi Nadal</li>
              <li>Matthias Brenninkmeijer</li>
              <br></br>
              <br></br>
              <br></br>
          </ul>
          </div>
          <div className="bg-persona">
          <h5>LCI Barcelona</h5>
            <ul class="peoble">
                <li>ALESSANDRO MANETTI <br></br> Phd Head of Europe LCI Education Concept designer</li>
                <li>MARIELE VIOLANO <br></br> Project Manager</li>
                <li>PEDRO COELHO <br></br> Concept Interior designer</li>
                <li>ANNA PALLEROLS <br></br> Concept graphic designer</li>
                <li>DAVID CARRETERO <br></br> Concept animation designer</li>
                <li>SALVATORE ELEFANTE <br></br> Concept photagraphy designer</li>
                <li>ESTEL VILASECA <br></br> Concept fashion designer</li>
                <li>OLIVER MONTIEL <br></br> Art Director</li>
                <li>DAVID GÓMEZ <br></br> Narrative designe</li>
                <li>SERGI CARBONELL <br></br> Motion graphics</li>    
                <li>JORDI ESPUNY <br></br> Front-end Developer</li>
                <li>EDGAR DOMIGUEZ <br></br> Estructure design</li>
                <li>CARLOS IZQUIERDO <br></br> Graphic design</li>
                <li>POL VALDES <br></br> Interiores</li>
                <li>MAURO GARCIA <br></br> Montaje y desmonatje</li>
                <li>JULIO PARDO <br></br> Stand LCI Barcelona</li>
                <li>GUILLERMO CALVO <br></br> Stand LCI Barcelonas</li>
                <li>ALINA CAPALLERAS <br></br> Stand LCI Barcelona</li>
            </ul>
        </div>

      </div>
    </main>
  );
}

export default Credits;
