import React from 'react';
import { Form, Select, Input } from 'antd';
import '../../styles/global.css'
const { Option } = Select;

const PhoneInput = ({ formData, handleChange, errors }) => {
    return (
        <div>
          <label htmlFor="phone">Phone Number:</label>
          <input type="text" defaultValue={"+34"} maxLength={4} id="countryCode" name="countryCode"  className="inputCountryCode" value={formData.countryCode} onChange={handleChange} required />
          <input type="tel" id="phone" name="phone" className='inputPhone' value={formData.phone} onChange={handleChange} required />

          {errors.phone && <div className="error">{errors.phone}</div>
          }
        </div>

    );

   /* return (
        <Form.Item
            label="Phone"
            validateStatus={errors.phone ? 'error' : ''}
            help={errors.phone || ''}
        >
            <Input.Group compact>
                <Select
                    id="countryCode"
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={(value) => handleChange({ target: { name: 'countryCode', value } })}
                    style={{ width: '30%' }}
                >
                    <Option value="">Code</Option>
                    <Option value="+1">+1 (USA)</Option>
                    <Option value="+44">+44 (UK)</Option>
                    <Option value="+91">+91 (India)</Option>
                    {/* Add more country codes as needed *//*}
                </Select>
                <Input
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="Phone Number"
                    value={formData.phone}
                    onChange={handleChange}
                    style={{ width: '70%' }}
                    required
                />
            </Input.Group>
        </Form.Item>
    );*/
};

export default PhoneInput;
