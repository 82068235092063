import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Form, Input, Button } from 'antd';
import GdprCheckbox from '../components/common/GdprCheckbox'; // Import the GdprCheckbox component
import './UserForm.css';
import PhoneInput from '../components/common/PhoneInput';
import FormComponent from '../components/common/FormComponent';
import settings from '../settings';
import apiService from '../services/apiService';
import Title from '../components/layout/Title';
function UserForm() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    image: null
  });
  const [errors, setErrors] = useState({});
  const [isGdprChecked, setIsGdprChecked] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const navigate = useNavigate();
  const handleGdprCheckedChange = (checked) => {

    setIsGdprChecked(checked);
    setIsSubmitDisabled(formData.name && formData.phone && formData.email && formData.image && isGdprChecked);
  };
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFormData({
        ...formData,
        image: acceptedFiles[0]
      });
      setImagePreview(URL.createObjectURL(acceptedFiles[0]));
    }
  });

  useEffect(() => {
    const isFormValid = formData.name && formData.phone && formData.email && formData.image && isGdprChecked;

    setIsSubmitDisabled(!isFormValid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name) {
      isValid = false;
      errors["name"] = "Name is required.";
    } else if (formData.name.length > 20) {
      isValid = false;
      errors["name"] = "Name cannot be longer than 20 characters.";
    }

    const phoneRegex = /^\d{9,12}$/;
    if (!formData.phone) {
      isValid = false;
      errors["phone"] = "Phone number is required.";
    } else if (!phoneRegex.test(formData.phone)) {
      isValid = false;
      errors["phone"] = "Phone number must be between 9 and 12 digits.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      isValid = false;
      errors["email"] = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      isValid = false;
      errors["email"] = "Email is not valid.";
    } else if (formData.email.length > 100) {
      isValid = false;
      errors["email"] = "Email cannot be longer than 100 characters.";
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      

      try {
        const data = await apiService.submitFormData(formData);
        const hash = data.user_hash;
        navigate(`/hash/${hash}`, { state: { image: formData.image } });
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  return (
    <div>
      <Title />
      <div className="landing-page">
      <form onSubmit={handleSubmit} className="landing-form">
        <div>
          <p>Please enter the following information to start the process:</p>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          {errors.name && <div className="error">{errors.name}</div>}
        </div>
        <PhoneInput formData={formData} handleChange={handleChange} errors={errors} />
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>
        <div>
          <label htmlFor="image">Upload Image:</label>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop an image here, or click to select one</p>
          </div>
          {acceptedFiles.length > 0 && (
            <div>
              <strong>Selected file:</strong> {acceptedFiles[0].name}
            </div>
          )}
          {imagePreview && (
            <div className="image-preview">
              <img src={imagePreview} alt="Uploaded Preview" />
            </div>
          )}
        </div>

        <GdprCheckbox onGdprCheckedChange={handleGdprCheckedChange} />

        <button type="submit" className='submit-button' disabled={isSubmitDisabled}>Submit</button>

      </form>
    </div>
    </div>

  );
}

export default UserForm;
