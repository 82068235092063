import React, { useState } from 'react';
import { Modal, Button, Checkbox, Input } from 'antd';

const { TextArea } = Input;

const GdprCheckbox = ({ onGdprCheckedChange }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isGdprChecked, setIsGdprChecked] = useState(false);
    const [isGdprViewed, setIsGdprViewed] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsGdprViewed(true);
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleCheckboxChange = (e) => {
        setIsGdprChecked(e.target.checked);
        onGdprCheckedChange(e.target.checked);
    };

    return (
        <div>
            <Button className='buttonPrivacy' type="link" onClick={showModal}>
                Read Privacy Policy before accepting it
            </Button>
            <Modal title="GDPR Privacy Policy" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <TextArea rows={10} readOnly value="CLÁUSULA DE CESIÓN DE DATOS Y DE DERECHOS DE IMAGEN.

Por la presente, y en virtud de Ley Orgánica 1/1982 del 5 de mayo y Ley Orgánica 7/2021, de 26 de mayo, de protección de datos personales.
Autorizo de forma expresa a LCI Barcelona a la captación, reproducción y difusión de los datos personales y las imágenes de mi persona, registradas y tomadas durante el Sónar 2024 en el espacio LCI Barcelona. Cedo de manera gratuita el derecho a su posterior utilización en los elementos publicitarios que se utilizarán en las campañas de encuestas e informes relacionados con la actividad docente. " />
            </Modal>
            <Checkbox className='gdprCheckBox' onChange={handleCheckboxChange} checked={isGdprChecked} disabled={!isGdprViewed}>
                I agree to the GDPR privacy policy
            </Checkbox>
        </div>
    );
};

export default GdprCheckbox;
