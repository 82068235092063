import React from 'react';
import '../../styles/global.css';

function Title() {
  return (
    <div className='title-div'>
      <h2 className='title'>
        FEEL<br />YOUR<br />
        <span>OUTFIT</span>
      </h2>
      <div className="title-text"><p>Hi! I’m Groovi, an Artificial inteligence developed by LCI Barcelona, whose mission is to analyze your outfit and create an audiovisual experience based on your style. Enjoy the experience.</p></div>
    </div>
  );
}

export default Title;
