import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AlbumCover from '../components/features/album/AlbumCover';
import Recommendations from '../components/features/recommendations/Recommendations';
import SpotifyPlayer from '../components/common/SpotifyPlayer';
import Jam from '../components/features/jam/Jam';
import QRCode from '../components/features/qr/QRCode';
import './UserGallery.css'
import { useLocation } from 'react-router-dom';
import apiService from '../services/apiService';
import { ReactComponent as Grid } from '../assets/img/grid.svg'; // Adjust the path as necessary
import Title from '../components/layout/Title';

function UserGallery() {
  const { hash } = useParams();
  const [userData, setUserData] = useState(null);
  const location = useLocation();
  const [loadingRecommendations, setLoadingRecommendations] = useState(true);
  const [loadingCover, setLoadingCover] = useState(true);
  const [loadingSong, setLoadingSong] = useState(true)
  const [recommendations, setRecommendations] = useState(null);
  const [albumCover, setAlbumCover] = useState(null);
  const [songUrl, setSongUrl] = useState(null);
  const [qrUrl, setQrUrl] = useState(null);
  const [showSong, setShowSong] = useState(true);  // State to control the display of AlbumCover
  useEffect(() => {

    const fetchData = async () => {
      setLoadingRecommendations(true);
      setLoadingCover(true);
      setLoadingSong(true);

      // Call to getUserData to check if user data already exists
      const responseUserData = await apiService.getUserData(hash);
      setUserData(responseUserData);

      setQrUrl(responseUserData.qr_url);
      
      setRecommendations(responseUserData.recommendations);
      setLoadingRecommendations(false);

      setAlbumCover(responseUserData.image_url);
      setLoadingCover(false);

      setShowSong(true);  // Show the Song after 10 minutes
      setSongUrl(responseUserData.song_url); 
      setLoadingSong(false);

      // Check if 10 minutes have passed since data_creation
      // const creationTime = new Date(responseUserData.data_creation);
      // const tenMinutesLater = new Date(creationTime.getTime() + 10 * 60000);
      // const currentTime = new Date();
      // if (currentTime > tenMinutesLater) {
      //   setShowSong(true);  // Show the Song after 10 minutes
      //   setSongUrl(responseUserData.song_url); 
      //   setLoadingSong(false);
      // } else {
      //   setShowSong(false);  // Otherwise, hide it
      // }
    };

    fetchData();
  }, [hash]);

  return (
    <div className="hash-page">
      <div className='wrapper center' >
        <Title />
      <div className='qr-code'>
        {qrUrl ? <img src={qrUrl} alt='QR Code' /> : <p>Loading...</p>}
      </div>
    </div>
      

      {loadingRecommendations ? (
        <div className="spinner">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <>
          <Recommendations recommendations={recommendations} />
          <SpotifyPlayer artist={recommendations.band_recommendation} playList={recommendations.playlist_recommendation[0]} />



        </>
      )}
      {loadingCover ? (
        <div className="spinner">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <>
          <h4>YOUR COVER:</h4>
          <AlbumCover albumCover={albumCover} />
          <Grid />
        </>
      )}
      {loadingSong ? (
        <div className="spinner">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : showSong ? (
        <>
        <Jam albumCover={albumCover} song={songUrl} />

        </>
      ) : null}

    </div>
  );
}

export default UserGallery;
/*
{loadingSong ? (
        <div className="spinner">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <>

        </>
      )}
<Jam prompt='hardTecno minimalist'/>

/* /><AlbumCover albumCover={albumCover} />
<AlbumCover />
          <Recommendations userHashProp={hash} onRecommendationsFetched={handleRecommendationsFetched} />
          <Jam />
          <SpotifyPlayer artist={artist} />
<Recommendations />
          <Jam />
          <SpotifyPlayer artist={artist} />
function HashPage() {
  const { hash } = useParams();
  const [artist, setArtist] = useState('Nirvana'); // Example artist
  return (
    <div className="hash-page">
      <QRCode userHash={hash}/>
      <AlbumCover />
      <Recommendations />
      <Jam />
      <SpotifyPlayer artist={artist} />
    </div>
  );
} <QRCode userHash='2de802b0e74632636443389098ec8a5e3172dc9b4eb8e9f556c35516b70dc9dd'/><MoodSection />
        <Recommendations uploadeFile='./img/paul.jpg' userHash='2de802b0e74632636443389098ec8a5e3172dc9b4eb8e9f556c35516b70dc9dd'/>
        <Jam />
        <Footer />
        <AlbumCover/>
        <AlbumCover imagePrompt='techno house' userHash='2de802b0e74632636443389098ec8a5e3172dc9b4eb8e9f556c35516b70dc9dd'/>
        <Bands />
        <Songs />

        <Jam />
         */
