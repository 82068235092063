import React from 'react';

const OutfitTitle = ({ outfit_title }) => {
  const words = outfit_title.split(' ');
  const lastWord = words.pop();

  return (
    <div className="outfit-title">
      <h2 style={{textTransform: 'uppercase',
                  fontSize: '3.4rem',
                  fontWeight: 500,
                  lineHeight: 0.8,
                  maxWidth: '100%',
                  textAlign: 'center',
                  aling: 'center',
                  margin: '10px auto' }}>
        {words.map((word, index) => (
          <div key={index} style={{ display: 'block' }}>{word}</div>
        ))} <span style={{ color: '#E3212C', display: 'block'}}>{lastWord}</span>
      </h2>
    </div>
  );
};

export default OutfitTitle;