import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AlbumCover from '../components/features/album/AlbumCover';
import Recommendations from '../components/features/recommendations/Recommendations';
import SpotifyPlayer from '../components/common/SpotifyPlayer';
import Jam from '../components/features/jam/Jam';
import QRCode from '../components/features/qr/QRCode';
import './UserPage.css'
import { useLocation } from 'react-router-dom';
import apiService from '../services/apiService';
import { ReactComponent as Grid } from '../assets/img/grid.svg'; // Adjust the path as necessary
import Title from '../components/layout/Title';

function UserPage() {
  const { hash } = useParams();
  const location = useLocation();
  const { image } = location.state || {};
  const [loadingRecommendations, setLoadingRecommendations] = useState(true);
  const [loadingCover, setLoadingCover] = useState(true);
  const [loadingSong, setLoadingSong] = useState(true)
  const [recommendations, setRecommendations] = useState(null);
  const [albumCover, setAlbumCover] = useState(null);
  const [song, setSong] = useState(null);
  useEffect(() => {

    const fetchData = async () => {
      setLoadingRecommendations(true);
      setLoadingCover(true);
      setLoadingSong(true);

      //Llamada con hash para ver si ya se creo

      // Call to getUserData to check if user data already exists
     // const responseUserData = await apiService.getUserData(hash);
       // If user data does not exist, proceed with other API calls
      const recommendationsResponse = await apiService.getRecommendations(image, hash);

      setRecommendations(recommendationsResponse);
      setLoadingRecommendations(false);
      const albumCoverResponse = await apiService.generateAlbumCover(recommendationsResponse.image_prompt, hash);

      setAlbumCover(albumCoverResponse);
      setLoadingCover(false);

      const songResponse = await apiService.generateSong(recommendationsResponse.prompt_generation, hash);
    
      setSong(songResponse);
      setLoadingSong(false);
    };

    fetchData();
  }, []);

  return (
    <div className="hash-page">
      <Title />
      <QRCode userHash={hash} />
      

      {loadingRecommendations ? (
        <div className="spinner">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <>
          <Recommendations recommendations={recommendations} />
          <SpotifyPlayer artist={recommendations.band_recommendation} playList={recommendations.playlist_recommendation[0]} />



        </>
      )}
      {loadingCover ? (
        <div className="spinner">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <>
          <h4>YOUR COVER:</h4>
          <AlbumCover albumCover={albumCover} />
          <Grid />
        </>
      )}
      {loadingSong ? (
        <div className="spinner">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <>
        <Jam albumCover={albumCover} song={song} />

        </>
      )}

    </div>
  );
}

export default UserPage;
/*
{loadingSong ? (
        <div className="spinner">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <>

        </>
      )}
<Jam prompt='hardTecno minimalist'/>

/* /><AlbumCover albumCover={albumCover} />
<AlbumCover />
          <Recommendations userHashProp={hash} onRecommendationsFetched={handleRecommendationsFetched} />
          <Jam />
          <SpotifyPlayer artist={artist} />
<Recommendations />
          <Jam />
          <SpotifyPlayer artist={artist} />
function HashPage() {
  const { hash } = useParams();
  const [artist, setArtist] = useState('Nirvana'); // Example artist
  return (
    <div className="hash-page">
      <QRCode userHash={hash}/>
      <AlbumCover />
      <Recommendations />
      <Jam />
      <SpotifyPlayer artist={artist} />
    </div>
  );
} <QRCode userHash='2de802b0e74632636443389098ec8a5e3172dc9b4eb8e9f556c35516b70dc9dd'/><MoodSection />
        <Recommendations uploadeFile='./img/paul.jpg' userHash='2de802b0e74632636443389098ec8a5e3172dc9b4eb8e9f556c35516b70dc9dd'/>
        <Jam />
        <Footer />
        <AlbumCover/>
        <AlbumCover imagePrompt='techno house' userHash='2de802b0e74632636443389098ec8a5e3172dc9b4eb8e9f556c35516b70dc9dd'/>
        <Bands />
        <Songs />

        <Jam />
         */
