import React, { useState, useEffect } from 'react';
import './styles/global.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import UserForm from './pages/UserForm';
import UserPage from './pages/UserPage';
import UserGallery from './pages/UserGallery'; // Import your new page
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Title from './components/layout/Title';
import PasswordForm from './pages/PasswordForm';
import settings from './settings';
import useScrollToTop from './components/common/ScrollToTop';
import Credits from './components/layout/Credits';


function ScrollToTopComponent() {
  useScrollToTop();
  return null; // This component does not render anything.
}


function App() {
  const [auth, setAuth] = useState(() => {
    // Initialize state from sessionStorage
    const savedAuth = sessionStorage.getItem('auth');
    return savedAuth === 'true'; // Convert string to boolean
  });

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  useEffect(() => {
    // Save the auth state to sessionStorage whenever it changes
    sessionStorage.setItem('auth', auth);
  }, [auth]);

  useEffect(() => {
    // Check if the 'Authorization' cookie is set only if auth is false
    if (!auth) {
      const checkAuth = () => {
        const authCookie = getCookie('Authorization');
       
        if (authCookie) {
          setAuth(true);
        }
      };
      checkAuth();
    }
  }, [auth]);
  

  return (
    <div className="app">
      <Router>
      <ScrollToTopComponent />
        <main>
          <Header />    
          <div className='wrapper'>
            <Routes>
              <Route path="/user/:hash" element={<UserGallery />} />
              <Route path="/credits" element={<Credits />} />
              {auth ? (
               <>
                  <Route path="/" element={<UserForm />} />
                  <Route path="/hash/:hash" element={<UserPage />} />
                
              </>
              ) : (
                 <Route path="*" element={<PasswordForm setAuth={setAuth} />} />
              )}
            </Routes>
          </div>
        </main>

        <Footer />

      </Router>
    </div >
  );
}

export default App;
